import { ProductItem, ProductTypes, ProductTypeChoiceString } from '@core/features';
// import { setProductTypes } from '@core/features/common/catalogReducer';
// import { productTypeSelector } from '@core/features/common/selectors';
import { ProductItemThumbnail } from '@core/ui/molecules/Item/ProductItemThumbnail';
import { map } from 'lodash';
import * as React from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import styles from './productPageTemplate.scss';
// import { Checkbox } from '@core/ui/atoms';

export const ProductPageTemplate: React.FunctionComponent<ProductItem[]> = items => {
  // const selectedTypes = useSelector(productTypeSelector);
  // const dispatch = useDispatch();

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   dispatch(
  //     setProductTypes({
  //       selectedTypes:
  //         event.target.name === ProductTypes.all
  //           ? { [event.target.name]: true }
  //           : {
  //               ...selectedTypes,
  //               [event.target.name]: !selectedTypes[ProductTypes[event.target.name]],
  //               [ProductTypes.all]: false,
  //             },
  //     })
  //   );
  // };

  return (
    <div className={styles.productPageTemplate}>
      <div className={styles.productPageSideNav}>
        <div className={styles.sideNavForm}>
          <span className={styles.label}>Types de produits</span>
          {map(ProductTypes, productType => (
            <React.Fragment key={productType}>
              <div key={productType} style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Checkbox
                  checked={selectedTypes?.[ProductTypes[productType]]}
                  onChange={handleChange}
                  name={productType}
                /> */}
                <span>{ProductTypeChoiceString[ProductTypes[productType]]}</span>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className={styles.productsLayout}>
        {map(items, item => (
          <ProductItemThumbnail key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};
