const getBackGroundUrl = (el: HTMLElement | null) => {
  var bg = '';
  if (el) {
    if (document.defaultView && document.defaultView.getComputedStyle) {
      // Firefox
      bg = document.defaultView.getComputedStyle(el, '').backgroundImage;
    } else {
      // try and get inline style
      bg = el?.style.backgroundImage;
    }
  }
  return bg.replace(/url\(['"]?(.*?)['"]?\)/i, '$1');
};

// TODO: transform this into a thunkAction
const isLoadingBackgroundImage = (ids: string[]) => {
  ids.forEach(id => {
    const imageToLoad = document.createElement('img');
    imageToLoad.src = getBackGroundUrl(document.getElementById(id));
    imageToLoad.onload = () => console.warn('image is loaded');
  });
};

export const loadingUtils = {
  getBackGroundUrl,
  isLoadingBackgroundImage,
};
