import image from '@core/assets/images/Cartes-Maude2-min.png';
import { InfoFooterProps, SiteTemplateProps } from '@core/features';
import { InfoFooter } from '@core/ui/molecules/InfoFooter/InfoFooter';
import { Room } from '@material-ui/icons';
import * as React from 'react';

import styles from './siteTemplate.scss';

const infoFooterProps: InfoFooterProps = {
  mapProps: {
    mapCenter: { lat: 46.836578705395674, lng: -71.2228357665833 },
    defaultZoom: 13,
    googleApiKey: GOOGLE_API ?? '',
    markers: [{ lat: 46.836578705395674, lng: -71.2228357665833, pointerSvg: <Room /> }],
  },
  infoProps: image,
};

export function SiteTemplate({ children }: SiteTemplateProps) {
  return (
    <div className={styles.siteTemplate}>
      {children}
      <InfoFooter {...infoFooterProps} />
    </div>
  );
}
