import { createAction, handleActions } from 'redux-actions';

import { CatalogStore, ProductItem, ProductTypes } from '../types';

enum CatalogActions {
  requestFetchProducts = 'fetchProducts',
  successFetchProducts = 'successFetchProducts',
  errorFetchProducts = 'errorFetchProducts',
  setProductTypes = 'setProductType',
}

interface Payload {
  selectedTypes: { [key: string]: boolean };
  products: ProductItem[];
  error: string;
}

export const requestFetchProducts = createAction(CatalogActions.requestFetchProducts);
export const successFetchProducts = createAction<Pick<Payload, 'products'>>(CatalogActions.successFetchProducts);
export const errorFetchProducts = createAction<Pick<Payload, 'error'>>(CatalogActions.errorFetchProducts);
export const setProductTypes = createAction<Pick<Payload, 'selectedTypes'>>(CatalogActions.setProductTypes);

const initialState: CatalogStore = {
  inRequest: false,
  selectedTypes: { [ProductTypes.all]: true },
};

export const catalogReducer = handleActions<CatalogStore, Payload>(
  {
    [CatalogActions.requestFetchProducts]: state => ({ ...state, inRequest: true }),
    [CatalogActions.successFetchProducts]: (state, { payload }) => ({
      ...state,
      inRequest: false,
      user: payload!.products,
    }),
    [CatalogActions.errorFetchProducts]: (state, { payload }) => ({
      ...state,
      inRequest: false,
      error: payload!.error,
    }),
    [CatalogActions.setProductTypes]: (state, { payload }) => ({ ...state, selectedTypes: payload.selectedTypes }),
  },
  initialState
);
