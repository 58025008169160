import './assets/styles/global.scss';

import * as React from 'react';
import { hot } from 'react-hot-loader/root';

import { entryRoutes } from './common/entryRoutes';
import { renderRouting } from './common/routing';
import { SiteTemplate } from './ui/templates';

export const App = hot(() => {
  return <SiteTemplate>{renderRouting(entryRoutes)}</SiteTemplate>;
});
