import { HomePageWithSplitLayoutProps } from '@core/features';
import * as React from 'react';

import styles from './homePageTemplate.scss';

export const HomePageWithSplitLayoutTemplate: React.FunctionComponent<HomePageWithSplitLayoutProps> = ({
  tabTitle,
  leftLayout,
  rightLayout,
}) => {
  React.useEffect(() => {
    window.document.title = tabTitle;
  }, []);

  return (
    <div id="lalala" className={styles.homePage}>
      <div className={styles.splitLayout}>
        <div className={styles.leftLayout}>{leftLayout}</div>
        <div className={styles.rightLayout}>{rightLayout}</div>
      </div>
    </div>
  );
};
