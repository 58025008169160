import { createSelector } from 'reselect';

import { AppState } from '@core/common/reducer';

export const commonRootSelector = (state: AppState) => state.common;
export const catalogRootSelector = (state: AppState) => state.catalog;
export const modalRootSelector = (state: AppState) => state.modals;

export const userSelector = createSelector(commonRootSelector, common => common.user);
export const userFetchedSelector = createSelector(commonRootSelector, common => common.inRequest);
export const userErrorSelector = createSelector(commonRootSelector, common => common.error);

export const productTypeSelector = createSelector(catalogRootSelector, catalog => catalog.selectedTypes);
export const modalSelector = createSelector(modalRootSelector, modals => modals);
