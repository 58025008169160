import * as React from 'react';

import styles from './button.scss';
import { Button } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

export const AddToCartButton: React.FunctionComponent<{
  onClick?: () => void;
}> = ({ onClick }) => (
  <Button className={styles.cartButton} onClick={onClick}>
    <ShoppingCartIcon fontSize="large" />
  </Button>
);
