// import { openModal } from '@core/features/common/modalReducer';
import { modalSelector } from '@core/features/common/selectors';
import { lunchMenuModalId, stValentingModalId } from '@core/features/constants';
import classNames from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
// import hearts from '../../../assets/images/hearts.svg'
// import easter from '../../../assets/images/easter_eggs.svg';

import styles from '../../../ui/templates/HomePageTemplates/homePageTemplate.scss';

// import { useHistory } from 'react-router';
interface HomePageLayoutInterface {
  card?: string;
  takeout?: string;
  pan?: string;
  basket?: string;
  cart?: string;
  christmasTree?: string;
}

export const HomePageLayout: React.FC<HomePageLayoutInterface> = ({
  card,
  takeout,
  pan,
  cart,
  basket,
  christmasTree,
}) => {
  // const dispatch = useDispatch();
  const modal = useSelector(modalSelector);
  // const reactHistory = useHistory();
  React.useEffect(() => {
    if (modal?.[lunchMenuModalId]?.isOpen === false) {
      history.replaceState(null, '', '/');
    }
    if (modal?.[stValentingModalId]?.isOpen === false) {
      history.replaceState(null, '', '/');
    }
  }, [modal]);
  return (
    <>
      <div className={classNames(styles.overlay, styles.cardBackground)}>
        <img className={styles.card} src={card} />
      </div>

      <div className={classNames(styles.primaryTitle, styles.overlay)}>
        <h1 style={{ textAlign: 'center' }}>Axé sur l'alimentation locale</h1>
      </div>

      {/* <div className={classNames(styles.secondaryTitle, styles.overlay)}>
        <h2>• Produits en vrac</h2>
        <h2>• Viandes d'élevage</h2>
        <h2>• Fromages fins québécois</h2>
        <h2>• Mets préparés</h2>
      </div> */}

      {/* <div
        className={classNames(styles.link, styles.firstLink, styles.overlay, styles.hover)}
        onClick={() => {
          if (window.innerWidth >= 960) {
            dispatch(openModal(lunchMenuModalId));
          } else {
            // window.scrollTo(0, 1340);
            const elem = document.getElementById('MobileLunchMenu');
            elem?.scrollIntoView();
          }
          history.replaceState(null, '', '/menu-midi');
        }}>
        <img src={pan} />
        <h3>
          Cantine • <span style={{ whiteSpace: 'nowrap' }}>Menu Midi</span>
        </h3>
      </div> */}

      {/* <div
        className={classNames(styles.link, styles.secondLink, styles.overlay, styles.hover)}
        onClick={() => {
          reactHistory.push('/epicerie');
        }}>
        <img src={cart} />
        <h3>Épicerie • Vrac</h3>
      </div>

      <div
        className={classNames(styles.link, styles.thirdLink, styles.overlay, styles.hover)}
        onClick={() => alert('Maude à la maison')}>
        <img src={takeout} />
        <h3>Maude à la maison</h3>
      </div> */}

      {/* <div
        className={classNames(styles.link, styles.thirdLink, styles.overlay, styles.hover)}
        onClick={() => { window.open('https://paniermaudealamaison.square.site/?fbclid=IwAR3ImKKyIYw3L55xmc9uS_KTYpsWyidReXn-LXTESmtpsPv7eJxvm-fwuBg', '_blank');}}>
        <img src={easter} />
        <h3>
          <span style={{ whiteSpace: 'nowrap', fontSize: '30upx' }}>Menu de Pâques</span>
        </h3>
      </div> */}

      {/* <div
        className={classNames(styles.link, styles.thirdLink, styles.overlay, styles.hover)}
        onClick={() => {
          // if (window.innerWidth >= 960) {
          //   dispatch(openModal(wineMenuModalId));
          // } else {
          //   // window.scrollTo(0, 1340);
          //   // const elem = document.getElementById('MobileWineMenu');
          //   // elem?.scrollIntoView();
          window.open('https://paniermaudealamaison.square.site/s/order', '_blank');
          // }
          // history.replaceState(null, '', '/menu-midi');
        }}>
        <img src={christmasTree} />
        <h3>Menu de Noël !</h3>
      </div> */}

      {/* <div
        className={classNames(styles.link, styles.takeOutButton, styles.overlay, styles.hover)}
        onClick={() => {
          window.open('https://order.koomi.com/maude-epicerie-laboratoire-culinaire-p31Y51R4yE', '_blank');
        }}>
        <h3>Pour emporter</h3>
      </div> */}

      <div
        className={classNames(styles.link, styles.reservationButton, styles.overlay, styles.hover)}
        onClick={() => {
          window.open('https://widgets.libroreserve.com/WEB/QC016535866426/book', '_blank');
        }}>
        <h3>Réservez une place</h3>
      </div>
    </>
  );
};
