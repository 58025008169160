import { AppApi } from '@core/api';
import { AppState } from '@core/common/reducer';
import { Dispatch } from 'redux';

import { lunchMenuModalId, stValentingModalId} from '../constants';
import { ProductTypes } from '../types';
import { ProductItem, User } from '../types/types';
import { errorFetchProducts, requestFetchProducts, successFetchProducts } from './catalogReducer';
import { errorFetchUser, requestFetchUser, successFetchUser } from './commonReducer';
import { openModal } from './modalReducer';

export const homePageActions = () => console.warn('get here the home page assets');

export function getUser(name: string) {
  return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
    try {
      dispatch(requestFetchUser());
      const user: User = await requestApi.get(`users/${name}`);
      dispatch(successFetchUser({ user }));
    } catch ({ message }) {
      dispatch(errorFetchUser({ error: message }));
    }
  };
}
// no need of product type since the product type will be in the store at this point
export function getProductItems(type: ProductTypes) {
  return async (dispatch: Dispatch, getState: () => AppState, { requestApi }: AppApi) => {
    try {
      dispatch(requestFetchProducts());
      const products: ProductItem[] = await requestApi.get(`users/${name}`);
      dispatch(successFetchProducts({ products }));
    } catch ({ message }) {
      dispatch(errorFetchProducts({ error: message }));
    }
  };
}

export function redirectToLunchMenu() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const screenWidth = window?.innerWidth;
    try {
      if (screenWidth >= 960) {
        dispatch(openModal(lunchMenuModalId));
      } else {
        const elem = document.getElementById('MobileLunchMenu');
        elem?.scrollIntoView();
      }
    } catch ({ message }) {
      // handle the redirection error.
    }
  };
}

// export function redirectToNoelMenu() {
//   return async (dispatch: Dispatch, getState: () => AppState) => {
//     const screenWidth = window?.innerWidth;
//     try {
//       if (screenWidth >= 960) {
//         dispatch(openModal(noelMenuModalId));
//       } else {
//         const elem = document.getElementById('MobileNoelMenu');
//         elem?.scrollIntoView();
//       }
//     } catch ({ message }) {
//       // handle the redirection error.
//     }
//   };
// }

export function redirectToStValentingMenu() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    const screenWidth = window?.innerWidth;
    try {
      if (screenWidth >= 960) {
        dispatch(openModal(stValentingModalId));
      } else {
        const elem = document.getElementById('SVMobileMenu');
        elem?.scrollIntoView();
      }
    } catch ({ message }) {
      // handle the redirection error.
    }
  };
}

export function redirectToGroceryPage() {
  return async (dispatch: Dispatch, getState: () => AppState) => {
    try {
      const elem = document.getElementById('GroceryPage');
      elem?.scrollIntoView();
    } catch ({ message }) {
      // handle the redirection error
    }
  };
}
