import image from '@core/assets/images/Cartes-Maude2-min.png';
import { FooterMapProps, InfoFooterProps, MarkerProps } from '@core/features';
import { CloseButton, FooterButton } from '@core/ui/atoms';
import { SwipeableDrawer } from '@material-ui/core';
import { HelpOutline, NotListedLocationOutlined } from '@material-ui/icons';
import GoogleMapReact from 'google-map-react';
import { map, omit } from 'lodash';
import * as React from 'react';

import styles from './infoFooter.scss';

export const InfoFooter: React.FunctionComponent<InfoFooterProps> = ({ mapProps, infoProps }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const footerIcon = mapProps !== undefined ? <NotListedLocationOutlined /> : <HelpOutline />;
  return (
    <>
      <FooterButton text="Info" icon={footerIcon} onClick={() => setIsOpen(true)} />
      <SwipeableDrawer
        anchor="bottom"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        classes={{ paper: styles.infoFooter }}>
        <CloseButton trigger={() => setIsOpen(false)} className={styles.closeButton} />
        <InfoSection {...infoProps} />
        {mapProps && <FooterMap {...mapProps} />}
      </SwipeableDrawer>
    </>
  );
};

const Marker: React.FunctionComponent<MarkerProps> = props => (
  <div style={{ margin: '-20px 0 0 -15px', cursor: 'pointer' }}>{props.pointerSvg}</div>
);

const FooterMap: React.FunctionComponent<FooterMapProps> = ({
  googleApiKey,
  mapCenter,
  defaultZoom,
  onChildClick,
  markers,
}) => {
  return (
    <div className={styles.infoFooterMap}>
      <GoogleMapReact
        onClick={() => {
          window.location.href =
            'https://www.google.com/maps/place/MAUDE+%C3%A9picerie%2Flaboratoire+culinaire/@46.8355307,-71.2217142,17z/data=!4m5!3m4!1s0x4cb897b90495dfc3:0x12044faaaed5702!8m2!3d46.8365633!4d-71.2228558';
        }}
        bootstrapURLKeys={{ key: googleApiKey }}
        defaultCenter={mapCenter}
        defaultZoom={defaultZoom}
        onChildClick={onChildClick}>
        {map(markers, (markerProps: MarkerProps) => (
          <Marker
            key={`${markerProps.lat}-${markerProps.lng}`}
            lat={markerProps.lat}
            lng={markerProps.lng}
            {...omit(markerProps, 'lat', 'lng')}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

const InfoSection: React.FunctionComponent<object | string> = props => {
  return (
    <div className={styles.informationSection}>
      <img
        src={image}
        style={{ maxHeight: '100%', maxWidth: '100%', height: 'auto', width: 'auto', objectFit: 'contain' }}
      />
      <a className={styles.email} href="mailto:info@chezmaude.com?subject=Demande d'informations">
        info@chezmaude.com
      </a>
      {/* {map(props, (prop, key) => (
        <div key={key} style={{ marginBottom: '1rem' }}>
          <span style={{ fontSize: ' 22px', fontWeight: 700 }}>{`${key} : `}</span>
          <span style={{ fontSize: ' 20px' }}>{prop}</span>
        </div>
      ))} */}
    </div>
  );
};
