import { ProductPageTemplate } from '@core/ui/templates/ProductPageTemplate/productPageTemplate';
import * as React from 'react';
import { connect } from 'react-redux';
import { ProductTypes, ProductItem } from '..';

const catalogsItems: ProductItem[] = [
  {
    id: '1',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 1,
    name: 'First Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
  {
    id: '2',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 2,
    name: 'Second Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
  {
    id: '3',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 3,
    name: 'Third Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
  {
    id: '4',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 4,
    name: 'Fourth Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
  {
    id: '5',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 5,
    name: 'Fifth Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
  {
    id: '6',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 6,
    name: 'Sixth Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
  {
    id: '7',
    imageURL: 'https://drive.google.com/uc?id=13JP_VhgqmKjDhJ5aseFN4WzOHlkqxb5l',
    price: 7,
    name: 'Seventht Item',
    type: ProductTypes.vegetable,
    description:
      'Mauris auctor volutpat erat, a tincidunt purus congue sit amet. Sed condimentum mauris eget justo tincidunt, eget interdum enim ullamcorper. Ut sollicitudin velit quis auctor vestibulum. Nam lacinia blandit orci.',
    cartClick: () => console.warn('cart click'),
  },
];

const CatalogPage: React.FunctionComponent = () => <ProductPageTemplate {...catalogsItems} />;

export const ProductsPageContainer = connect()(CatalogPage);
