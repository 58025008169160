import { combineReducers } from 'redux';

import { commonReducer, catalogReducer, modalReducer } from '@core/features/common';
import { CatalogStore, CommonStore, ModalStore } from '@core/features';

export interface AppState {
  common: CommonStore;
  catalog: CatalogStore;
  modals: ModalStore;
}

export const reducer = combineReducers<AppState>({
  common: commonReducer,
  modals: modalReducer,
  catalog: catalogReducer,
});
