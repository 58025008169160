import * as React from 'react';
import { Route, Switch } from 'react-router';

import { EntryRoute } from './entryRoutes';

export function renderRouting(routes?: EntryRoute[]) {
  if (!routes) return null;

  return (
    <Switch>
      {routes.map(props => (
        // tslint:disable no-any
        <Route {...props} key={props.path as any} />
      ))}
    </Switch>
  );
}
