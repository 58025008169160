import { createAction, handleActions } from 'redux-actions';

import { ModalStore } from '../types';

enum ModalActions {
  openModal = 'OPEN_MODAL',
  closeModal = 'CLOSE_MODAL',
}

type Payload = string;

export const openModal = createAction(ModalActions.openModal);
export const closeModal = createAction(ModalActions.closeModal);

const initialState: ModalStore = {};

export const modalReducer = handleActions<ModalStore, Payload>(
  {
    [ModalActions.openModal]: (state, { payload }) => ({ ...state, [payload]: { isOpen: true } }),
    [ModalActions.closeModal]: (state, { payload }) => ({ ...state, [payload]: { isOpen: false } }),
  },
  initialState
);
