import { createAction, handleActions } from 'redux-actions';

import { CommonStore, User } from '../types';

enum CommonActions {
  requestFetchUser = 'fetchUser',
  successFetchUser = 'successFetchUser',
  errorFetchUser = 'errorFetchUser',
}

interface Payload {
  user: User;
  error: string;
}

export const requestFetchUser = createAction(CommonActions.requestFetchUser);
export const successFetchUser = createAction<Pick<Payload, 'user'>>(CommonActions.successFetchUser);
export const errorFetchUser = createAction<Pick<Payload, 'error'>>(CommonActions.errorFetchUser);

const initialState: CommonStore = {
  inRequest: false,
};

export const commonReducer = handleActions<CommonStore, Payload>(
  {
    [CommonActions.requestFetchUser]: state => ({ ...state, inRequest: true }),
    [CommonActions.successFetchUser]: (state, { payload }) => ({ ...state, inRequest: false, user: payload!.user }),
    [CommonActions.errorFetchUser]: (state, { payload }) => ({ ...state, inRequest: false, error: payload!.error }),
  },
  initialState
);
