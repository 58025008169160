import { HomePageWithItemsProps } from '@core/features';
import { PageTitle } from '@core/ui/atoms/Title';
import { PrimaryItem } from '@core/ui/molecules/Item';
import * as React from 'react';

import styles from './homePageTemplate.scss';

export const HomePageWithItemsTemplate: React.FunctionComponent<HomePageWithItemsProps> = ({
  tabTitle,
  pageTitles,
  primaryItems,
}) => {
  React.useEffect(() => {
    window.document.title = tabTitle;
  }, []);
  return (
    <div className={styles.homePage}>
      {primaryItems ? (
        <div className={styles.withItems}>
          {pageTitles?.primary ? (
            <div className={styles.homePageTitleArea}>
              <PageTitle main={pageTitles.primary} secondary={pageTitles.secondary} />
            </div>
          ) : null}
          <div className={styles.homePageLeftItemArea}>
            <PrimaryItem {...primaryItems[0]} />
          </div>
          <div className={styles.homePageCenterItemArea}>
            <PrimaryItem {...primaryItems[1]} />
          </div>
          <div className={styles.homePageRightItemArea}>
            <PrimaryItem {...primaryItems[2]} />
          </div>
        </div>
      ) : null}
    </div>
  );
};
