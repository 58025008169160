import { Button } from '@material-ui/core';
import classNames from 'classnames';
import * as React from 'react';

import styles from './button.scss';

export const FooterButton: React.FunctionComponent<{ icon?: React.ReactNode; text?: string; onClick?: () => void }> = ({
  icon,
  text,
  onClick,
}) => (
  <Button className={classNames(styles.footerButton, styles.large)} onClick={onClick}>
    {icon}
    <span className={styles.footerButtonText}>{text}</span>
  </Button>
);
