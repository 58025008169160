import classNames from 'classnames';
import * as React from 'react';
import { MenuItemInterface } from '../HomePageLayout';
import styles from './menu.scss';

export const MenuItem: React.FC<MenuItemInterface> = ({ itemName, description, price, ingredients, itemPicture }) => (
  <div className={styles.menuItem}>
    <h3 className={classNames(styles.itemTitle, 'no-margin')}>{itemName} </h3>
    <h4 className={classNames(styles.itemPrice, 'no-margin')}>{price}</h4>
    <p className={classNames(styles.itemDescription)}>{description}</p>
    <img src={itemPicture} className={classNames(styles.itemPicture)} />
  </div>
);
