import * as React from 'react';
import { RouteConfig, RouteConfigComponentProps } from 'react-router-config';
import { unnest } from 'ramda';

import { PreloadAction } from './preload';

import { commonRoutes } from '@core/features/common';

export interface ExtraRouteProps {
  preloadActions?: PreloadAction | PreloadAction[];
}

export interface RouteComponentProps<T = {}> extends RouteConfigComponentProps<T> {
  routes?: EntryRoute[];
}

export interface EntryRoute extends RouteConfig, ExtraRouteProps {
  routes?: EntryRoute[];
  component?: React.ComponentType<RouteComponentProps> | React.ComponentType;
}

export const entryRoutes = unnest([commonRoutes]) as EntryRoute[];
