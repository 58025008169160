import { closeModal } from '@core/features/common/modalReducer';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styles from './button.scss';

export const ModalCloseButton: React.FC<{ modalId: string }> = ({ modalId }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={classNames(styles.modalCloseButtonOutterDiv, 'overlay')}
      onClick={() => dispatch(closeModal(modalId))}
      tabIndex={1}>
      <div className={styles.modalCloseButtonInnerDiv}>
        <div className={styles.modalCloseButtonX} />
      </div>
    </div>
  );
};

export const CloseButton: React.FC<{ trigger: () => void; className: string }> = ({ trigger, className }) => (
  <div className={classNames(styles.modalCloseButtonOutterDiv, 'overlay', className)} onClick={trigger} tabIndex={1}>
    <div className={styles.modalCloseButtonInnerDiv}>
      <div className={styles.modalCloseButtonX} />
    </div>
  </div>
);
