import { ProductTypes } from '../types';

export const AppBarTitle = 'App Title';

export const lunchMenuModalId = 'lunchMenuModal';

export const paquesMenuModalId = 'pacquesMenuModal';

export const noelMenuModalId = 'noelMenuModal';

export const wineMenuModalId = 'wineMenuModal';

export const stValentingModalId = 'stValentinModalId'

export const ProductTypeChoiceString = {
  [ProductTypes.all]: 'Tous',
  [ProductTypes.vegetable]: 'Légumes',
  [ProductTypes.meat]: 'Viandes',
  [ProductTypes.fruit]: 'Fruits',
};
