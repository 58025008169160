import { AddToCartButton } from '@core/ui/atoms';
import classNames from 'classnames';
import * as React from 'react';

import styles from './item.scss';
import { ProductItem } from '@core/features';

export const ProductItemThumbnail: React.FunctionComponent<ProductItem> = ({
  imageURL,
  price,
  name,
  description,
  id,
  cartClick,
}) => (
  <div className={classNames(styles.item, styles.productItemThumbnail)}>
    {/* <span className={'styles.mobileItemTextAreaTitle'}>{name}</span> */}
    <a className={styles.titleArea} href={`produits/${id}`}>
      produit
    </a>
    <a className={styles.descriptionArea} href={`produits/${id}`}>
      {description}
    </a>
    <a className={styles.itemImageContainer} href={`produits/${id}`}>
      <img className={styles.itemImage} loading="lazy" src={imageURL} alt={name} />
    </a>
    <div className={styles.priceArea}>{`${price} $`}</div>
    <div className={styles.addToCartArea}>{<AddToCartButton onClick={cartClick} />}</div>
  </div>
);
