import { EntryRoute } from '@core/common/entryRoutes';
import * as React from 'react';

import { HomePageContainer, ProductsPageContainer } from '../layout';
// import { GroceryPageLayout } from '../layout/GroceryPageLayout/GroceryPageLayout';

// import { getUser } from './effects';
export const commonRoutes: EntryRoute[] = [
  {
    path: '/',
    exact: true,
    render: () => <HomePageContainer />,
    // preloadActions: () => getUser('weyheyhey'),
  },
  {
    path: '/produits',
    component: ProductsPageContainer,
  },
  {
    path: '/menu-midi',
    render: () => <HomePageContainer location={'lunch'} />,
  },
  // {
  //   path: '/paques',
  //   render: () => <HomePageContainer location={'paques'} />,
  // },
  // {
  //   path: '/epicerie',
  //   render: () => <GroceryPageLayout />,
  // },
];
