import { redirectToGroceryPage, redirectToLunchMenu, redirectToStValentingMenu } from '@core/features/common/effects';
// import { lunchMenuModalId } from '@core/features/constants';
// import { MaterialModal } from '@core/ui/molecules';
import { HomePageWithSplitLayoutTemplate } from '@core/ui/templates';
import { omit } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';

import card from '../../../assets/images/Cartes-Maude-min.png';
import christmasTree from '../../../assets/images/christmas-tree.png';
import maudeSpring from '../../../assets/images/maudeSpring.jpg';
// import paquesMenu from '../../../assets/images/menu_paques_2022.jpg';
import noelImage from '../../../assets/images/noel_ketchup_2021.jpg';
import basket from '../../../assets/images/panier.png';
import cart from '../../../assets/images/panierepicerie-min.png';
import paques from '../../../assets/images/paques_2020.jpg';
import pan from '../../../assets/images/poelecuisine-min.png';
import takeout from '../../../assets/images/pouremporter-min.png';
// import sandwich from '../../../assets/images/sandwich-min.jpg';
// import sandViet from '../../../assets/images/sandwich-vietnamien-min.jpeg';
import maudeWinter from '../../../assets/images/winter_web.jpg';
import { userErrorSelector, userFetchedSelector, userSelector } from '../../common/selectors';
import { loadingUtils } from '../../common/utils/loadingUtils';
// import { LunchMenuLayout } from '../LunchMenu';
import { HomePageLayout } from './HomePageLayout';

// import SValentin from '../../../assets/images/SValentin.jpg';
// import SVMenu from '../../../assets/images/SVMenu.jpg';
// import maudeAuParcPic from '../../../assets/images/maudeAuParcPic.jpg';
const tabTitle = 'Maude Épicerie';

const homePageTitles: { primary?: string; secondary?: string } = {
  primary: 'Épicerie',
  secondary: 'Laboratoire Culinaire',
};

const imagesAssets = {
  card,
  maudeSpring,
  maudeWinter,
  takeout,
  pan,
  cart,
  basket,
  noelImage,
  christmasTree,
  paques,
};

export interface MenuItemInterface {
  itemName: string;
  description?: string;
  price: string;
  ingredients?: string[];
  itemPicture?: string;
}

// const menuItems: MenuItemInterface[] = [
//   {
//     itemName: 'Soupe du jour',
//     description:
//       'En crème, potage ou taillé nous vous préparons une bonne soupe tous les jours avec des légumes de saison.',
//     price: '6,00$',
//   },
//   {
//     itemName: 'Salade du marché',
//     description: 'Plusieurs choix de salades , fraîches et savoureuses !',
//     price: '4,50$',
//   },
//   {
//     itemName: 'Tartinade du moment',
//     description: 'Tartinade du moment servie avec un naan fait maison fraîchement grillé.',
//     price: '4,50$',
//   },
//   {
//     itemName: 'Grilled cheese',
//     description:
//       'Pain borderon / cendré des grands jardins (laiterie charlevoix, lait de vache) / tombé de poireaux, pommes caramélisées',
//     price: '11,80$',
//   },
//   {
//     itemName: 'Falacroquette',
//     description:
//       'Pois chiches (falafel) panées à l’anglaise / sauce tzatziki à l’ail confit / salade de concombres et tomates / épices zaatar / sauce piquante maison',
//     price: '16$',
//   },
//   {
//     itemName: 'Raviolis',
//     description:
//       'Raviolis maison / courge et ricotta maison / courges délicatas rôtis / pêche du jour / sauce vierge à la courge / boutons de marguerites',
//     price: '18$',
//   },
//   {
//     itemName: 'Ramen',
//     description:
//       'Consommé de boeufs, champignons et gingembre / kimchi / purée de carotte au sésame / tataki du moment / oeuf mollet / nouilles / champignons marinés',
//     price: '18,00$',
//   },
//   {
//     itemName: 'Risotto',
//     description:
//       'Risotto verde (pistou maison) / aubergine / caponata / chapelure / tofu fumé façon bacon bits / fromage Clo-Desroches ',
//     price: '16,00$',
//   },
// ];

// const lunchItems: MenuItemInterface[] = [
//   {
//     itemName: 'Sandwich au végépâte',
//     description: 'Pain borderon / végé-pâté maison / moutarde forte / mayonnaise / fromage / tomate / laitue',
//     price: '11,50$',
//   },
//   {
//     itemName: 'Pain brioché tempeh vietnamien',
//     description: 'Pain brioché / tempeh mariné / kimchi maison / shiitake confits / laitue',
//     price: '10.50$',
//   },
//   {
//     itemName: 'Croissant Jambon',
//     description: 'Croissant/ jambon / bacon / mayonnaise / fromage / laitue',
//     price: '9,00$',
//   },
//   {
//     itemName: 'Bun’s maison',
//     description:
//       'Pain bun’s maison / charcuterie (viande biologique de charlevoix)/ légumes grillés / mozzarella bufflone / épices zaatar maison / mayonnaise à la fleur d’ail / épinards',
//     price: '12.50$',
//   },
//   {
//     itemName: 'Bagel au saumon fumé maison',
//     description:
//       'Bagel / saumon fumé maison / tartinade au fromage de chèvre / bouton de marguerites / échalotes marinées / concombres / roquette',
//     price: '12.00$',
//   },
//   {
//     itemName: 'Bol maquereau fumé',
//     description: 'verdure / courgettes / esturgeon fumé / sauce tartare',
//     price: '13.00$',
//   },
//   {
//     itemName: 'Bol de saumon fumée maison',
//     description:
//       'Saumon fumé maison/ Quinoa / sauce tartare / roquette / rémoulade de céleri-rave et pomme / kale déshydraté/ coutons',
//     price: '12.50$',
//   },
//   {
//     itemName: 'Bol au poulet fattouche',
//     description:
//       'Verdure/ poulet mariné/ riz/ tzatziki/ courgette mariné/ tabouleh/ épices zaatar maison/  vinaigrette fattouche/ sauce piquante maison/ chips de pita',
//     price: '13,95$',
//   },
//   {
//     itemName: "Bol de tempeh à l'asiatique",
//     description:
//       'Tempeh mariné / épinards / carottes rotis / / shitake confit / avoine nu biologique / kimchie maison / vinaigrette miso et érable',
//     price: '12,50$',
//   },
//   {
//     itemName: 'Bol de betteraves rôties et fromage de chèvre  ',
//     description:
//       'Verdure / betterave rôties / quinoa /  fromage de chèvre / mélange de noix maison / fromage de chèvre',
//     price: '13.00$ ',
//   },
// ];

// const maudeAuParcMenu: MenuItemInterface[] = [
//   {
//     itemName: 'Plateaux de charcuteries',
//     description: 'Charcuterie d’artisans local et terrines maison / moutarde à l’ancienne / marinades',
//     price: '17.00$',
//   },
//   {
//     itemName: 'Plateau de fromages',
//     description: 'Sélection de fromage québécois / beurre de pomme / craquelin maison',
//     price: '16.00$',
//   },
//   {
//     itemName: 'Plateaux de crudités pimpées',
//     description: 'Légumes du moment / hummus / olives / marinade / naan maison',
//     price: '15.00$',
//   },
//   {
//     itemName: 'Brocolis',
//     description:
//       'Mini-brocolis rôtis / mousse de feta de brebis (fromagerie de la moutonnière) / olives / huile aux fines herbes et fleurs d’ail / échalotes marinées aux piments / chips de pita ',
//     price: '16.50$',
//   },

//   {
//     itemName: 'Flanc braisé',
//     description:
//       'Mousse de pomme de terre et fromage bête à séguin / flanc de porc braisé (viande bio charlevoix) / salade de choux caraflex / carottes marinées / pain brûlé (intégral de Borderon et fil) / chips de pommes de terre ',
//     price: '17.80$',
//   },

//   {
//     itemName: 'Ceviche',
//     description:
//       'Turbo (gaspésie) / lait d’avoine à la rhubarbe confite / concombre / mini poivrons grillés / fleur de ciboulette marinée',
//     price: '16.50$',
//   },

//   {
//     itemName: 'Porchetta maison façon vitello tonnato',
//     description:
//       'Porchetta maison / mayonnaise au thon / tomates cerises / boutons de marguerite / huile aux épinards / bébé kale',
//     price: '17.50$',
//   },

//   {
//     itemName: 'Cannellonis de choux-rave',
//     description:
//       'Choux-rave / rillette d’omble chevalier fumé au sapin / mousse crevette / boutons de marguerite / chiffonnade de choux-kale et pomme / chips kale',
//     price: '18.00$',
//   },

//   {
//     itemName: 'Burrata',
//     description:
//       'Burrata / salade de tomates cerises confites, basilic vinaigrette à la cameline et ail confit / chutney de fraises ',
//     price: '22.00$',
//   },

//   {
//     itemName: 'Petit plus!',
//     description:
//       'Au choix : Pain et beurre à la fleur d’ail et fines herbes / olives mariné au shishito / légumes marinés',
//     price: '3.00$',
//   },
// ];
const mapStateToProps = createStructuredSelector({
  user: userSelector,
  fetched: userFetchedSelector,
  error: userErrorSelector,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  toLunchMenu: () => dispatch(redirectToLunchMenu()),
  toGrocery: () => dispatch(redirectToGroceryPage()),
  // toNoelMenu: () => dispatch(redirectToNoelMenu())
  toStValentinMenu: () => dispatch(redirectToStValentingMenu()),
});

const enhance = connect(mapStateToProps, mapDispatchToProps);

export const HomePage: React.FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & { location?: string }
> = ({ location, toLunchMenu, toGrocery, toStValentinMenu }) => {
  React.useEffect(() => {
    loadingUtils.isLoadingBackgroundImage(['lalala']);
    if (location === 'lunch') {
      toLunchMenu();
    }
    if (location === 'st-valentin') {
      toStValentinMenu();
    }
  }, [location]);

  const LeftLayout = <HomePageLayout {...omit(imagesAssets, [maudeWinter])} />;
  const RightLayout = <img src={imagesAssets.maudeSpring} style={{ maxHeight: '100%', maxWidth: '100%' }} />;

  return (
    <>
      <HomePageWithSplitLayoutTemplate
        tabTitle={tabTitle}
        pageTitles={homePageTitles}
        images={imagesAssets}
        leftLayout={LeftLayout}
        rightLayout={RightLayout}
      />

      {/* <MaterialModal id={lunchMenuModalId} childrenClassName="modal left-centered" closeButton>
        <>
          <LunchMenuLayout key={1} menuItems={menuItems} menuName="Menu midi" headerImageBackground={sandViet} />
          <LunchMenuLayout key={2} menuItems={lunchItems} menuName="Comptoir Lunch" headerImageBackground={sandwich} />
        </>
      </MaterialModal> */}

      {/* <MaterialModal id={'maudeAuParc'} childrenClassName="modal left-centered" closeButton>
        <LunchMenuLayout
          key={1}
          menuItems={maudeAuParcMenu}
          menuName="Maude au parc"
          headerImageBackground={maudeAuParcPic}
        />
      </MaterialModal> */}

      {/* <MaterialModal id={paquesMenuModalId} childrenClassName="noel modal left-centered" closeButton>
        <div style={{ height: '100%'}}>
          <img src={paquesMenu} style={{ maxHeight: '100%', maxWidth: '100%'}} />

        </div>
      </MaterialModal> */}
      {/* <div
        className="flex-center overlay hover pacques-button"
        onClick={() => {
          window.open(
            'https://paniermaudealamaison.square.site/?fbclid=IwAR27O3MQiVzo6HbbfpuAvN8NIxMZRN9-mTbybjbLRZLi3a42w40_GPQtD30',
            '_blank'
          );
        }}
        style={{ visibility: modals?.[paquesMenuModalId]?.isOpen ? 'visible' : 'hidden' }}
        >
        <h2>Commandez !</h2>
      </div> */}

      {/* <div id="MobilePaquesMenu" style={{ backgroundColor: 'antiquewhite' }}>
        <img src={paquesMenu} style={{ maxHeight: '100%', maxWidth: '100%' }} />
        <div
          className="flex-center overlay hover"
          onClick={() => {
            window.open(
              'https://paniermaudealamaison.square.site/?fbclid=IwAR27O3MQiVzo6HbbfpuAvN8NIxMZRN9-mTbybjbLRZLi3a42w40_GPQtD30',
              '_blank'
            );
          }}
          style={{ position: 'sticky', bottom: '3%', width: '175px', justifyContent: 'center', marginLeft: '5%', backgroundColor: '#146B3A', border: '4px #BB2528 solid', borderRadius: '8px' }}>
          <h2>Commandez !</h2>
        </div>
      </div> */}

      {/* <MaterialModal id={wineMenuModalId} childrenClassName="modal modal-wine left-centered" closeButton>
        <div style={{ height: '100%' }}>
          <img src={wineModal} style={{ maxHeight: '100%', maxWidth: '100%' }} />
        </div>
      </MaterialModal>
      <div
        className="flex-center overlay hover wine-button"
        onClick={() => {
          window.open(
            'https://formfacade.com/public/102025272171379429',
            '_blank'
          );
        }}
        style={{ visibility: modals?.wineMenuModal?.isOpen ? 'visible' : 'hidden' }}>
        <h2>Cliquez ici !</h2>
      </div> */}

      {/* <div id="MobileWineMenu" style={{ backgroundColor: 'antiquewhite' }}>
        <img src={wineMobile} style={{ maxHeight: '100%', maxWidth: '100%' }} />
        <div
          className="flex-center overlay hover"
          onClick={() => {
            window.open(
              'https://formfacade.com/public/102025',
              '_blank'
            );
          }}
          style={{ position: 'sticky', bottom: '3%', width: '175px', justifyContent: 'center', marginLeft: '5%' }}>
          <h2>Cliquez ici !</h2>
        </div>
      </div> */}

      {/* <div id="MobileLunchMenu">
        <LunchMenuLayout key={3} menuItems={menuItems} menuName="Menu midi" headerImageBackground={sandViet} />
        <LunchMenuLayout key={4} menuItems={lunchItems} menuName="Comptoir Lunch" headerImageBackground={sandwich} />
      </div> */}

      {/* <div id="maudeAuParcMenu">
        <LunchMenuLayout
          key={1}
          menuItems={maudeAuParcMenu}
          menuName="Maude au parc"
          headerImageBackground={maudeAuParcPic}
        />
      </div> */}
    </>
  );
};

export const HomePageContainer = enhance(HomePage);
